//@flow
import React from 'react';
import {useEffect} from 'react';
import {useRef} from 'react';
import {useState} from 'react';
import {useContext} from 'react';
import {useCallback} from 'react';

import {styled} from 'dcme-style/core';
import {Box} from 'dcme-style/layout';
import {Flex} from 'dcme-style/layout';
import {Button} from 'dcme-style/affordance';
import {Clickable} from 'dcme-style/affordance';
import {Icon} from 'dcme-style/affordance';
import {Paper} from 'dcme-style';

import {Theme} from 'dcme-style/theme';
import {DarkTheme} from 'dcme-style/theme';

import {Stage, Layer, Rect, Image} from 'react-konva';
import {useStrictMode} from 'react-konva';

useStrictMode(true);

const theme = DarkTheme({
    bg: '#000000',
    copy: '#FFFFFF',
    link: '#49B75A',
    touch: '#ffffff',
    hover: '#222222',
    focus: '#333333',
    panelButton: '#d86d56',
    panelButtonActive: '#FFFFFF',
    primary: '#f38a72',
    primaryLight: '#ffbeb0',
    primaryDeep: '#bd3232',
    overBg: {
        copy: '#000000',
        hover: '#222222',
        focus: '#333333'
    }
});

//
// APPLICATION COMPONENTS
//

const StageContext = React.createContext();

const PaintLayer = (props): Node => {
    const stage = useContext(StageContext);

    let [layer, setLayer] = useState();
    let [image, setImage] = useState();

    let [{canvas, context}, setCanvas] = useState({});
    let [isPaint, setIsPaint] = useState(false);
    let lastPointerPosition = useRef();

    useEffect(() => {
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');

        setCanvas({canvas, context});

        canvas.width = 500;
        canvas.height = 300;

        context.strokeStyle = '#000000';
        context.lineJoin = 'round';
        context.lineWidth = 35;

    }, []);

    let onMouseDown = useCallback((e) => {
        setIsPaint(true);
        let pos = stage.getPointerPosition();
        lastPointerPosition.current = pos;

        context.beginPath();
        context.moveTo(pos.x + 0.5, pos.y + 0.5);
        context.lineTo(pos.x + 0.5, pos.y + 0.5 - 1);
        context.closePath();
        context.stroke();

        layer.batchDraw();

    }, [layer, context]);

    let onMouseUp = useCallback((e) => {
        setIsPaint(false);
    }, []);

    let onMouseOut = useCallback((e) => {
        setIsPaint(false);
    }, []);

    let onMouseMove = useCallback((e) => {
        if(!isPaint) return;

        context.beginPath();

        let lastPos = lastPointerPosition.current;
        context.moveTo(lastPos.x + 0.5, lastPos.y + 0.5);
        let pos = stage.getPointerPosition();

        context.lineTo(pos.x + 0.5, pos.y + 0.5);
        context.closePath();
        context.stroke();

        lastPointerPosition.current = pos;
        layer.batchDraw();

    }, [isPaint, layer, context]);

    let onCreateLayer = useCallback((layer) => {
        if(!layer) return;
        let context = layer.getContext()._context;
        context.imageSmoothingEnabled = false;
        setLayer(layer);
    }, []);

    return <Layer ref={onCreateLayer}>
        {canvas && <Image
            ref={setImage}
            image={canvas}
            x={0}
            y={0}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            onMouseOut={onMouseOut}
        />}
    </Layer>;
};

export const MosfezPixel = (): Node => {
    let [stage, setStage] = useState();

    let onExport = useCallback(() => {
        let uri = stage.toDataURL();
        let name = 'export.png';
        let link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [stage]);

    return <Paper>
        <Clickable onClick={onExport}>export</Clickable>
        <Flex
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            {/*<Theme theme={theme}>*/}
                <Stage width={500} height={500} ref={setStage}>
                    {stage && <StageContext.Provider value={stage}>
                        <Layer>
                            <Rect
                                x={0}
                                y={0}
                                width={500}
                                height={500}
                                fill="#778844"
                            />
                        </Layer>
                        <PaintLayer />
                    </StageContext.Provider>}
                </Stage>
            {/*</Theme>*/}
        </Flex>
    </Paper>
};

/*
{[...Array(10)].map((_, i) => (
    <Star
      key={i}
      x={Math.random() * 500}
      y={Math.random() * 500}
      numPoints={5}
      innerRadius={10}
      outerRadius={20}
      fill="#89b717"
      opacity={0.8}
      draggable
      rotation={Math.random() * 180}
      shadowColor="black"
      shadowBlur={10}
      shadowOpacity={0.6}
      onDragStart={() => /*handleDragStart{}}
      onDragEnd={() => /*handleDragEnd{}}
    />
))}
*/
